import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { Collapse as RCollapse, Card } from 'reactstrap';
import Button from 'components/Button';
import styles from './collapse.module.scss';

const Collapse = ({ title, subMenus }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const isActive = !!_.find(subMenus, {
    slug: location.pathname,
  });

  return (
    <li>
      <Button handleClick={toggle} type={isActive ? styles.active : ''}>
        {title}
        <span>{isOpen ? '-' : '+'}</span>
      </Button>
      <RCollapse isOpen={isOpen}>
        <Card className={styles.card}>
          <ul>
            {subMenus?.length &&
              subMenus.map(({ title, slug, id }) => (
                <li className={styles.subMenu} key={id}>
                  <Link activeClassName={styles.active} to={slug}>
                    {title}
                  </Link>
                </li>
              ))}
          </ul>
        </Card>
      </RCollapse>
    </li>
  );
};

export default Collapse;
