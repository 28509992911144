import {
  faEnvelope,
  faEye,
  faHeart,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faUserTag } from '@fortawesome/free-solid-svg-icons';

const MY_ACCOUNT_MENUS = {
  MENU: 'MY ACCOUNT',
  SUB_MENUS: [
    {
      subMenu: 'Profile',
      link: '/auth/profile',
      subMenuIcon: faUser,
    },
    {
      subMenu: 'My Favorites',
      link: '/auth/following',
      subMenuIcon: faHeart,
    },
    {
      subMenu: 'Registration History',
      link: '/auth/registration-history',
    },
    // {
    //   subMenu: 'recently Viewed',
    //   link: '/auth/recently-viewed',
    // },
    // {
    //   subMenu: 'Registration History',
    //   link: '/auth/registration-history',
    // },
    // {
    //   subMenu: 'Communication Preferences',
    // },
    {
      subMenu: 'Logout',
      subMenuIcon: faArrowRight,
      isLogout: true,
    },
  ],
};

export { MY_ACCOUNT_MENUS };
