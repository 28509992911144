import * as React from 'react';

const Icon = props => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M15.935 20.582v-5.593l5.403 2.806-5.403 2.787zM27.8 14.02s-.196-1.372-.795-1.976c-.76-.793-1.613-.796-2.004-.842C22.202 11 18.004 11 18.004 11h-.008s-4.198 0-6.997.202c-.392.046-1.243.05-2.005.842-.599.604-.794 1.976-.794 1.976S8 15.63 8 17.24v1.51c0 1.612.2 3.222.2 3.222s.195 1.372.794 1.976c.762.793 1.761.768 2.206.85 1.6.154 6.8.201 6.8.201s4.203-.006 7.001-.208c.39-.046 1.243-.05 2.004-.843.6-.604.795-1.976.795-1.976s.2-1.61.2-3.221v-1.51c0-1.611-.2-3.222-.2-3.222z"
        />
        <path
          stroke="#FFF"
          d="M18 .5A17.472 17.472 0 00.5 18a17.474 17.474 0 005.116 12.384A17.474 17.474 0 0018 35.5a17.474 17.474 0 0012.385-5.116A17.477 17.477 0 0035.5 18a17.474 17.474 0 00-5.116-12.385A17.477 17.477 0 0018 .5z"
        />
      </g>
    </svg>
  );
};

export default Icon;
