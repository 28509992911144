import { useStaticQuery, graphql } from 'gatsby';

export const useStaticQueryGetNavItems = () => {
  const query = useStaticQuery(
    graphql`
      query navItems {
        header: contentfulNavigationMenu(type: { eq: "Header" }) {
          title
          navigationElements {
            title
            slug
            viewMode
            id
            subElements {
              title
              slug
              viewMode
              id
            }
          }
        }

        footer: contentfulNavigationMenu(type: { eq: "Footer" }) {
          title
          navigationElements {
            title
            slug
            viewMode
            id
            subElements {
              title
              slug
              viewMode
              id
            }
          }
        }
      }
    `
  );
  return {
    footerNavItems: query.footer.navigationElements,
    headerNavItems: query.header.navigationElements,
  };
};
