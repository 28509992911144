import * as React from 'react';

const Icon = props => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#FFF"
          d="M18 .5A17.47 17.47 0 00.5 18a17.472 17.472 0 005.116 12.384A17.474 17.474 0 0018 35.5a17.469 17.469 0 0017.501-17.499A17.47 17.47 0 0018.001.5z"
        />
        <path
          fill="#FFF"
          d="M17.7 10h-3.1a4.87 4.87 0 00-1.9.4 3.916 3.916 0 00-2.3 2.3 7.806 7.806 0 00-.4 1.9v6.6c.005.654.14 1.3.4 1.9a3.916 3.916 0 002.3 2.3 7.806 7.806 0 001.9.4h6.6a4.87 4.87 0 001.9-.4 3.916 3.916 0 002.3-2.3 7.806 7.806 0 00.4-1.9v-6.6a4.87 4.87 0 00-.4-1.9 3.916 3.916 0 00-2.3-2.3 7.816 7.816 0 00-1.9-.4h-3.5zm5.4 3.7a.987.987 0 01-.9 1 .92.92 0 01-.9-1 .905.905 0 111.8 0zm-5.2 6.9a2.609 2.609 0 01-2.6-2.7 2.669 2.669 0 012.6-2.7 2.609 2.609 0 012.6 2.7 2.548 2.548 0 01-2.6 2.7zm0-6.7c2.26.01 4.09 1.84 4.1 4.1a4.055 4.055 0 01-4.1 4.1 4.119 4.119 0 01-4.1-4.1 4.184 4.184 0 014.1-4.1z"
        />
      </g>
    </svg>
  );
};

export default Icon;
