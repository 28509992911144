import * as React from 'react';

const Icon = props => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M14.3 26.1A12.188 12.188 0 018 24.3a7.987 7.987 0 006.1-1.7 3.99 3.99 0 01-3.8-2.8 4.074 4.074 0 001.9-.1 4.057 4.057 0 01-3.3-4 4.14 4.14 0 001.9.5 4.13 4.13 0 01-1.3-5.4A11.16 11.16 0 0018 15a4.08 4.08 0 014-5 4.154 4.154 0 013 1.3 8.247 8.247 0 002.6-1 4.331 4.331 0 01-1.8 2.2c.82-.105 1.626-.306 2.4-.6a7.93 7.93 0 01-2 2.1c0 5.7-4.3 12.1-11.9 12.1z"
        />
        <path
          stroke="#FFF"
          d="M18 .5A17.472 17.472 0 00.5 18a17.474 17.474 0 005.116 12.384A17.474 17.474 0 0018 35.5a17.474 17.474 0 0012.385-5.116A17.477 17.477 0 0035.5 18a17.474 17.474 0 00-5.116-12.385A17.477 17.477 0 0018 .5z"
        />
      </g>
    </svg>
  );
};

export default Icon;
