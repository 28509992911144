import React, { Fragment, useEffect, useState } from 'react';
import DesktopHeader from './desktop';
import MobileHeader from './mobile';

export default ({
  transparentHeader,
  hideHeaderItems = false,
  disableLogoClick = false,
}) => {
  const [isDesktop, setDesktop] = useState(true);
  function checkDevice(x) {
    if (x.matches) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  }
  useEffect(() => {
    const isBrowser = typeof window !== undefined;
    if (isBrowser) {
      const x = window.matchMedia('(min-width: 1199px)');
      checkDevice(x); // Call listener function at run time
      x.addListener(checkDevice); // Attach listener function on state changes
    }
  }, []);
  return (
    <Fragment>
      {isDesktop ? (
        <DesktopHeader
          transparentHeader={transparentHeader}
          hideHeaderItems={hideHeaderItems}
          disableLogoClick={disableLogoClick}
        />
      ) : (
        <MobileHeader
          transparentHeader={transparentHeader}
          hideHeaderItems={hideHeaderItems}
          disableLogoClick={disableLogoClick}
        />
      )}
    </Fragment>
  );
};
