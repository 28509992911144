import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { clearAMSession } from 'utils/session';
import Button from 'components/Button';
import { MY_ACCOUNT_MENUS } from 'src/data/header';
import styles from './desktop.module.scss';
import AccountProfileIcon from '../../../../static/svg/AccountProfileIcon';
import RecentlyViewedIcon from '../../../../static/svg/RecentlyViewedIcon';
import Icon from '../../../../static/svg/HeartIcon';
import LogOutIcon from '../../../../static/svg/LogOutIcon';
import CommunicationPreferencesIcon from '../../../../static/svg/communicationPreferences';
import ProfilePaddleIcon from '../../../../static/svg/ProfilePaddleIcon';

const MyAccount = () => {
  const { logout } = useAuth0();
  const renderIcons = subMenu => {
    switch (subMenu) {
      case 'Profile':
        return <AccountProfileIcon />;
        break;
      case 'My Favorites':
        return <Icon color={'#1C5BAE'} height={'18px'} width={'20px'} />;
      case 'Registration History':
        return <ProfilePaddleIcon color={'#1C5BAE'} />;
      // case 'recently Viewed':
      //   return <RecentlyViewedIcon />;
      // case 'Communication Preferences':
      //   return <CommunicationPreferencesIcon />;
      default:
        break;
    }
  };
  return (
    <div className={styles.userProfile}>
      <div className={styles.userIcon}>
        <FontAwesomeIcon icon={faUserCircle} />
      </div>
      <ul className={styles.desktopSubMenu}>
        {MY_ACCOUNT_MENUS.SUB_MENUS.map((row, index) => (
          <li key={`headerSubMenu${index}`}>
            {row.isLogout ? (
              <Button
                handleClick={async () => {
                  await clearAMSession();
                  return logout({ returnTo: window.location.origin });
                }}
                style={{ 'text-transform': 'uppercase' }}
              >
                <LogOutIcon />
                Logout
              </Button>
            ) : (
              <div>
                <Link activeClassName={styles.active} to={row.link}>
                  {renderIcons(row.subMenu)}
                  {row.subMenu}
                </Link>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyAccount;
