import * as React from 'react';

const Icon = props => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M21.8 15.58h-2.4v-1.6c.2-1.5 2.7-1.2 2.7-1.2v-2.6c-.1 0-5-1.2-5.9 2.6-.13.96-.163 1.932-.1 2.9H14v2.9h2.2v7.8h3.2v-7.8h2.5l.3-2.9h-.4v-.1z"
        />
        <path
          stroke="#FFF"
          d="M18 .5A17.472 17.472 0 00.5 18a17.474 17.474 0 005.116 12.384A17.474 17.474 0 0018 35.5a17.474 17.474 0 0012.385-5.116A17.477 17.477 0 0035.5 18a17.474 17.474 0 00-5.116-12.385A17.477 17.477 0 0018 .5z"
        />
      </g>
    </svg>
  );
};

export default Icon;
