import * as Yup from 'yup';

const INITIAL_VALUES = {
  name: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  message: '',
};
const TERMS_INITIAL_VALUES = {
  name: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '+1',
  message: '',
};
const AUCTION_INITIAL_VALUES = {
  name: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '+1',
  message:
    'I would like to know more about this auction once further details are available.',
};
const FOOTER_INITIAL_VALUES = {
  name: '',
  email: '',
};
const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Please enter Full Name'),
  message: Yup.string().required('Please enter Message'),
  phoneNumber: Yup.string().required('Phone is required').nullable(),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
  confirmEmail: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.')
    .test('test', 'Email not match', function (value) {
      const { email } = this.parent;
      return value === email;
    }),
});
const FOOTER_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Please enter Full Name'),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
});

export {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
  TERMS_INITIAL_VALUES,
  AUCTION_INITIAL_VALUES,
  FOOTER_INITIAL_VALUES,
  FOOTER_VALIDATION_SCHEMA,
};
