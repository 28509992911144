import React from 'react';
import { Modal } from 'reactstrap';
import VehiclesSearchTypeahead from 'components/VehiclesSearchTypeahead';
import Button from 'components/Button';
import styles from './searchModal.module.scss';

const SearchModal = ({ searchModal, toggle }) => {
  return (
    <Modal
      isOpen={searchModal}
      backdrop={false}
      toggle={toggle}
      className={styles.searchModalContainer}
    >
      <div className={styles.searchModalHeader}>
        <VehiclesSearchTypeahead
          placeholder="Search Vehicles"
          toggle={toggle}
        />
        <Button type="app-transparent-no-border-button" handleClick={toggle}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default SearchModal;
