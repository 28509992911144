import React, { Fragment, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Burger from './Burger';
import SearchModal from './SearchModal';
import SideMenu from './SideMenu';
import SearchIcon from 'svg/SearchIcon';
import GoodingLogo from 'static/svg/GoodingLogo';
import { BLUE, WHITE } from 'utils/colorsConstants';
import styles from './mobile.module.scss';

const documentRef = typeof document !== `undefined` ? document : null;

const MobileHeader = ({
  transparentHeader,
  hideHeaderItems,
  disableLogoClick,
}) => {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const toggleSearchModal = () => setSearchModal(!searchModal);

  if (documentRef) {
    if (isSideMenuOpen) {
      documentRef.body.classList.add('overflow-hidden');
    } else {
      documentRef.body.classList.remove('overflow-hidden');
    }
  }

  return (
    <Fragment>
      <header
        className={`${styles.appHeader} ${
          transparentHeader ? styles.transparentHeaderBody : ''
        }`}
      >
        <div className={styles.header}>
          {disableLogoClick ? (
            <GoodingLogo color={transparentHeader ? WHITE : BLUE} />
          ) : (
            <Link to="/" title="link to home page">
              <GoodingLogo color={transparentHeader ? WHITE : BLUE} />
            </Link>
          )}
          <div
            className={`${styles.hamburgerWrapper} ${
              hideHeaderItems ? 'd-none' : 'd-flex'
            }`}
          >
            <Button handleClick={toggleSearchModal} title="search">
              <SearchIcon color={transparentHeader ? '#FFF' : '#242424'} />
            </Button>
            <Burger
              handleClick={() => setSideMenuOpen(!isSideMenuOpen)}
              transparentHeader={transparentHeader}
            />
          </div>
        </div>
      </header>
      {searchModal && (
        <SearchModal searchModal={searchModal} toggle={toggleSearchModal} />
      )}

      <SideMenu
        closeSideMenu={() => setSideMenuOpen(false)}
        isOpen={isSideMenuOpen}
      />
    </Fragment>
  );
};

MobileHeader.propTypes = {
  transparentHeader: PropTypes.bool,
};

export default MobileHeader;
