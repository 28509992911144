import React from 'react';
import Link from 'components/Link/Link';
import { useAuth0 } from '@auth0/auth0-react';
import { useStaticQueryGetNavItems } from 'shared/queries/navigations';
import Collapse from './Collapse';
import { Roles, RolesKey } from 'utils/RolesConstants';
import styles from './mobile.module.scss';

export default () => {
  const { isAuthenticated, user } = useAuth0();
  const { headerNavItems } = useStaticQueryGetNavItems();

  const renderNavigationItems = headerNavItems =>
    headerNavItems?.length &&
    headerNavItems.map(({ title, subElements, viewMode, slug }, index) => {
      const showMenu =
        viewMode.includes('Visitor') ||
        (isAuthenticated && viewMode.includes('Logged In')) ||
        (user?.[RolesKey].includes(Roles.kioskAdmin) &&
          viewMode.includes(Roles.kioskAdmin));

      if (showMenu) {
        return renderItemsAfterCheck(subElements, title, slug, index);
      } else {
        return null;
      }
    });

  const renderItemsAfterCheck = (subElements, title, slug, index) =>
    subElements ? (
      <Collapse key={`sideMenu${index}`} subMenus={subElements} title={title} />
    ) : (
      <li key={`sideMenu${index}`}>
        <Link activeClassName={styles.active} to={slug}>
          {title}
        </Link>
      </li>
    );

  return renderNavigationItems(headerNavItems);
};
