import * as React from 'react';

const GoodingLogo = props => {
  const { color = '#D8D8D8', prefix } = props;
  const clipId1 = constructId(prefix, 'clip1');
  const clipId2 = constructId(prefix, 'clip2');
  const clipId3 = constructId(prefix, 'clip3');
  const clipId4 = constructId(prefix, 'clip4');
  const clipId5 = constructId(prefix, 'clip5');
  const clipId6 = constructId(prefix, 'clip6');
  const clipId7 = constructId(prefix, 'clip7');
  const clipId8 = constructId(prefix, 'clip8');
  const clipId9 = constructId(prefix, 'clip9');
  return (
    <svg
      fill={color}
      height={40}
      width={140}
      viewBox="0 0 148 50"
      {...props}
      style={{ padding: '4px 0px' }}
    >
      <defs>
        <clipPath id={clipId1}>
          <path d="M 0 25 L 18 25 L 18 46.570313 L 0 46.570313 Z M 0 25 " />
        </clipPath>
        <clipPath id={clipId2}>
          <path d="M 21 25 L 36 25 L 36 46.570313 L 21 46.570313 Z M 21 25 " />
        </clipPath>
        <clipPath id={clipId3}>
          <path d="M 39 25 L 54 25 L 54 46.570313 L 39 46.570313 Z M 39 25 " />
        </clipPath>
        <clipPath id={clipId4}>
          <path d="M 57 26 L 73 26 L 73 46.570313 L 57 46.570313 Z M 57 26 " />
        </clipPath>
        <clipPath id={clipId5}>
          <path d="M 77 26 L 92 26 L 92 46.570313 L 77 46.570313 Z M 77 26 " />
        </clipPath>
        <clipPath id={clipId6}>
          <path d="M 92 26 L 108 26 L 108 46.570313 L 92 46.570313 Z M 92 26 " />
        </clipPath>
        <clipPath id={clipId7}>
          <path d="M 110 26 L 126 26 L 126 46.570313 L 110 46.570313 Z M 110 26 " />
        </clipPath>
        <clipPath id={clipId8}>
          <path d="M 128 26 L 144 26 L 144 46.570313 L 128 46.570313 Z M 128 26 " />
        </clipPath>
        <clipPath id={clipId9}>
          <path d="M 144 26 L 147.601563 26 L 147.601563 30 L 144 30 Z M 144 26 " />
        </clipPath>
      </defs>
      <g id={constructId(prefix, 'surface1')}>
        <path d="M 11.121094 20.519531 L 11.121094 19.128906 C 10.738281 20.164063 8.746094 20.929688 7.410156 20.929688 C 3.425781 20.929688 0.210938 17.707031 0.210938 13.726563 L 0.210938 7.207031 C 0.210938 3.222656 3.425781 0.00390625 7.410156 0.00390625 C 11.148438 0.00390625 14.230469 2.839844 14.585938 6.496094 L 10.984375 7.234375 C 10.984375 5.269531 9.375 3.6875 7.382813 3.660156 C 5.472656 3.660156 3.863281 5.242188 3.863281 7.207031 L 3.863281 13.726563 C 3.863281 15.636719 5.472656 17.246094 7.410156 17.246094 C 9.375 17.246094 11.09375 15.882813 11.09375 13.726563 L 11.09375 13.371094 L 8.9375 13.371094 L 8.9375 10.097656 L 14.640625 10.097656 L 14.640625 20.519531 L 11.121094 20.519531 " />
        <path d="M 29.371094 7.234375 C 29.371094 5.269531 27.761719 3.6875 25.773438 3.6875 C 23.859375 3.6875 22.253906 5.296875 22.253906 7.207031 L 22.253906 13.726563 C 22.253906 15.636719 23.859375 17.246094 25.796875 17.246094 C 27.761719 17.246094 29.371094 15.636719 29.371094 13.726563 Z M 25.796875 20.929688 C 21.816406 20.929688 18.597656 17.707031 18.597656 13.726563 L 18.597656 7.207031 C 18.597656 3.222656 21.816406 0.00390625 25.796875 0.00390625 C 29.78125 0.00390625 33.03125 3.222656 33.03125 7.207031 L 33.03125 13.726563 C 33.03125 17.707031 29.78125 20.929688 25.796875 20.929688 " />
        <path d="M 47.625 7.234375 C 47.625 5.269531 46.015625 3.6875 44.027344 3.6875 C 42.113281 3.6875 40.503906 5.296875 40.503906 7.207031 L 40.503906 13.726563 C 40.503906 15.636719 42.113281 17.246094 44.050781 17.246094 C 46.015625 17.246094 47.625 15.636719 47.625 13.726563 Z M 44.050781 20.929688 C 40.070313 20.929688 36.847656 17.707031 36.847656 13.726563 L 36.847656 7.207031 C 36.847656 3.222656 40.070313 0.00390625 44.050781 0.00390625 C 48.03125 0.00390625 51.28125 3.222656 51.28125 7.207031 L 51.28125 13.726563 C 51.28125 17.707031 48.03125 20.929688 44.050781 20.929688 " />
        <path d="M 66.421875 7.644531 C 66.421875 5.679688 64.8125 4.097656 62.847656 4.097656 L 59.300781 4.097656 L 59.300781 16.835938 L 62.847656 16.835938 C 64.8125 16.835938 66.421875 15.226563 66.421875 13.316406 Z M 62.824219 20.519531 L 55.644531 20.519531 L 55.644531 0.414063 L 62.847656 0.414063 C 66.832031 0.414063 70.078125 3.632813 70.078125 7.613281 L 70.078125 13.316406 C 70.078125 17.300781 66.804688 20.519531 62.824219 20.519531 " />
        <path d="M 72.914063 20.519531 L 72.914063 16.835938 L 76.570313 16.835938 L 76.570313 4.097656 L 72.914063 4.097656 L 72.914063 0.414063 L 83.636719 0.414063 L 83.636719 4.097656 L 80.226563 4.097656 L 80.226563 16.835938 L 83.636719 16.835938 L 83.636719 20.519531 L 72.914063 20.519531 " />
        <path d="M 99 20.519531 L 91.660156 7.480469 L 91.660156 20.519531 L 88.140625 20.519531 L 88.140625 0.414063 L 91.660156 0.414063 L 98.859375 13.179688 L 98.859375 0.414063 L 102.433594 0.414063 L 102.433594 20.519531 L 99 20.519531 " />
        <path d="M 117.6875 20.519531 L 117.6875 19.128906 C 117.304688 20.164063 115.3125 20.929688 113.976563 20.929688 C 109.996094 20.929688 106.773438 17.707031 106.773438 13.726563 L 106.773438 7.207031 C 106.773438 3.222656 109.996094 0.00390625 113.976563 0.00390625 C 117.710938 0.00390625 120.796875 2.839844 121.152344 6.496094 L 117.550781 7.234375 C 117.550781 5.269531 115.941406 3.6875 113.949219 3.660156 C 112.039063 3.660156 110.429688 5.242188 110.429688 7.207031 L 110.429688 13.726563 C 110.429688 15.636719 112.039063 17.246094 113.976563 17.246094 C 115.941406 17.246094 117.660156 15.882813 117.660156 13.726563 L 117.660156 13.371094 L 115.503906 13.371094 L 115.503906 10.097656 L 121.207031 10.097656 L 121.207031 20.519531 L 117.6875 20.519531 " />
        <g clipPath={`url(#${clipId1})`} clipRule="nonzero">
          <path d="M 10.914063 30.417969 L 10.914063 30.363281 C 10.914063 28.75 9.707031 27.574219 8.070313 27.574219 C 6.261719 27.574219 5.027344 28.839844 5.027344 30.476563 L 5.027344 30.53125 C 5.027344 31.710938 5.515625 32.601563 7.039063 34.238281 C 9.597656 33.378906 10.914063 32.144531 10.914063 30.417969 Z M 11.519531 41.910156 L 6.148438 36.367188 C 3.476563 37.339844 2.300781 38.980469 2.300781 40.675781 L 2.300781 40.730469 C 2.300781 42.886719 4.023438 44.523438 6.492188 44.523438 C 8.390625 44.523438 10.054688 43.519531 11.519531 41.910156 Z M 12.898438 43.316406 C 11.058594 45.296875 8.9375 46.445313 6.320313 46.445313 C 2.699219 46.445313 0 44.179688 0 40.816406 L 0 40.757813 C 0 38.089844 1.78125 36.164063 4.855469 34.957031 C 3.476563 33.347656 2.84375 32.113281 2.84375 30.53125 L 2.84375 30.476563 C 2.84375 27.832031 4.96875 25.707031 8.160156 25.707031 C 10.972656 25.707031 13.042969 27.746094 13.042969 30.246094 L 13.042969 30.304688 C 13.042969 32.859375 11.230469 34.496094 8.300781 35.53125 L 12.898438 40.214844 C 13.789063 38.921875 14.59375 37.398438 15.3125 35.734375 L 17.179688 36.59375 C 16.289063 38.488281 15.367188 40.214844 14.28125 41.648438 L 17.78125 45.242188 L 16.058594 46.5625 L 12.898438 43.316406 " />
        </g>
        <g clipPath={`url(#${clipId2})`} clipRule="nonzero">
          <path d="M 28.261719 46.570313 C 24.277344 46.570313 21.058594 43.351563 21.058594 39.367188 L 21.058594 32.847656 C 21.058594 28.863281 24.277344 25.644531 28.261719 25.644531 C 32.078125 25.644531 35.191406 28.535156 35.464844 32.300781 L 31.832031 32.875 C 31.832031 30.910156 30.222656 29.328125 28.261719 29.300781 C 26.351563 29.300781 24.769531 30.882813 24.769531 32.847656 L 24.769531 39.367188 C 24.769531 41.277344 26.351563 42.886719 28.261719 42.886719 C 30.222656 42.886719 31.832031 41.277344 31.832031 39.367188 L 35.464844 39.914063 C 35.191406 43.679688 32.078125 46.570313 28.261719 46.570313 " />
        </g>
        <g clipPath={`url(#${clipId3})`} clipRule="nonzero">
          <path d="M 49.921875 32.875 C 49.921875 30.910156 48.3125 29.328125 46.324219 29.328125 C 44.410156 29.328125 42.800781 30.9375 42.800781 32.847656 L 42.800781 39.367188 C 42.800781 41.277344 44.410156 42.886719 46.347656 42.886719 C 48.3125 42.886719 49.921875 41.277344 49.921875 39.367188 Z M 46.347656 46.570313 C 42.363281 46.570313 39.148438 43.351563 39.148438 39.367188 L 39.148438 32.847656 C 39.148438 28.863281 42.363281 25.644531 46.347656 25.644531 C 50.332031 25.644531 53.582031 28.863281 53.582031 32.847656 L 53.582031 39.367188 C 53.582031 43.351563 50.332031 46.570313 46.347656 46.570313 " />
        </g>
        <g clipPath={`url(#${clipId4})`} clipRule="nonzero">
          <path d="M 68.71875 46.160156 L 68.71875 35.65625 L 65.117188 45.152344 L 61.546875 35.441406 L 61.546875 46.160156 L 57.945313 46.160156 L 57.945313 26.054688 L 61.765625 26.054688 L 65.117188 34.839844 L 68.53125 26.054688 L 72.378906 26.054688 L 72.378906 46.160156 L 68.71875 46.160156 " />
        </g>
        <g clipPath={`url(#${clipId5})`} clipRule="nonzero">
          <path d="M 85.253906 29.65625 L 80.886719 29.65625 L 80.886719 35.277344 L 85.253906 35.277344 C 86.835938 35.277344 88.117188 34.019531 88.117188 32.492188 C 88.089844 30.910156 86.808594 29.65625 85.253906 29.65625 Z M 85.171875 38.792969 L 80.914063 38.792969 L 80.914063 46.160156 L 77.285156 46.160156 L 77.285156 26.054688 L 85.171875 26.054688 C 88.691406 26.054688 91.527344 28.894531 91.527344 32.382813 C 91.554688 35.929688 88.71875 38.792969 85.171875 38.792969 " />
        </g>
        <g clipPath={`url(#${clipId6})`} clipRule="nonzero">
          <path d="M 99.960938 31.78125 L 98.046875 38.875 L 101.949219 38.875 Z M 103.503906 46.160156 L 103.503906 44.414063 L 102.929688 42.394531 L 97.09375 42.394531 L 96.550781 44.414063 L 96.550781 46.160156 L 92.757813 46.160156 L 92.757813 44.660156 C 92.757813 44.414063 92.78125 44.195313 92.894531 43.976563 L 98.242188 26.054688 L 101.648438 26.054688 L 107.027344 43.921875 C 107.078125 44.140625 107.1875 44.386719 107.1875 44.632813 L 107.1875 46.160156 L 103.503906 46.160156 " />
        </g>
        <g clipPath={`url(#${clipId7})`} clipRule="nonzero">
          <path d="M 121.730469 46.160156 L 114.394531 33.121094 L 114.394531 46.160156 L 110.871094 46.160156 L 110.871094 26.054688 L 114.394531 26.054688 L 121.59375 38.820313 L 121.59375 26.054688 L 125.167969 26.054688 L 125.167969 46.160156 L 121.730469 46.160156 " />
        </g>
        <g clipPath={`url(#${clipId8})`} clipRule="nonzero">
          <path d="M 142.847656 29.765625 L 137.773438 37.511719 L 137.773438 46.160156 L 134.089844 46.160156 L 134.089844 37.511719 L 129.042969 29.710938 C 128.824219 29.464844 128.714844 29.082031 128.714844 28.726563 L 128.714844 26.027344 L 132.425781 26.027344 L 132.425781 28.128906 L 135.78125 33.800781 L 139.492188 28.128906 L 139.492188 26.027344 L 143.121094 26.027344 L 143.121094 28.726563 C 143.121094 29.109375 143.039063 29.464844 142.847656 29.765625 " />
        </g>
        <g clipPath={`url(#${clipId9})`} clipRule="nonzero">
          <path d="M 146.140625 27.472656 C 146.347656 27.472656 146.464844 27.371094 146.464844 27.1875 C 146.464844 27 146.339844 26.90625 146.132813 26.90625 L 145.824219 26.90625 L 145.824219 27.472656 Z M 145.488281 26.644531 L 146.195313 26.644531 C 146.539063 26.644531 146.804688 26.835938 146.804688 27.164063 C 146.804688 27.402344 146.675781 27.609375 146.425781 27.679688 C 146.484375 27.757813 146.808594 28.292969 146.875 28.371094 L 146.511719 28.371094 L 146.097656 27.714844 L 145.824219 27.714844 L 145.824219 28.371094 L 145.488281 28.371094 Z M 147.296875 27.535156 C 147.296875 26.753906 146.738281 26.257813 146.082031 26.257813 C 145.425781 26.257813 144.882813 26.742188 144.882813 27.535156 C 144.882813 28.320313 145.453125 28.785156 146.082031 28.785156 C 146.710938 28.785156 147.296875 28.316406 147.296875 27.535156 Z M 144.585938 27.535156 C 144.585938 26.6875 145.203125 26.015625 146.082031 26.015625 C 146.996094 26.015625 147.59375 26.679688 147.59375 27.535156 C 147.59375 28.359375 146.988281 29.050781 146.082031 29.050781 C 145.140625 29.050781 144.585938 28.359375 144.585938 27.535156 " />
        </g>
      </g>
    </svg>
  );
};

const constructId = (prefix, id) => {
  return `${prefix ? prefix : ''}${id}`;
};
export default GoodingLogo;
