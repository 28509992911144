import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import Button from 'components/Button';
import { setTimeout } from 'timers';

const tooltipDefaultValue = 'Copy to clipboard';
const tooltipCopiedValue = 'Copied to clipboard';

const CopyClipboard = ({ id, text }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState(tooltipDefaultValue);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const copyText = () =>
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText(tooltipCopiedValue);
      setTimeout(() => {
        toggleTooltip();
        setTooltipText(tooltipDefaultValue);
      }, 1000);
    });

  return (
    <div>
      <Button href="#" id={`${id}`} handleClick={() => copyText()}>
        {text}
      </Button>

      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={`${id}`}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};

export default CopyClipboard;
