import React, { Fragment } from 'react';
import AppHeader from 'components/Header';
import AppFooter from 'components/Footer';
import { nodePropType } from 'shared/propTypes';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import * as amplitude from '@amplitude/analytics-browser';
import './layout.scss';

const Layout = ({
  children,
  transparentHeader,
  paddingTop,
  hideFooter,
  hideHeaderItems,
  withOutBodyPadding,
  disableLogoClick,
}) => {
  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width" />
        <link
          type="text/css"
          rel="stylesheet"
          href={process.env.GATSBY_AUCTION_MOBILITY_CSS}
        />
      </Helmet>
      <div className="app-container">
        <AppHeader
          transparentHeader={transparentHeader}
          hideHeaderItems={hideHeaderItems}
          disableLogoClick={disableLogoClick}
        />
        <div
          role="main"
          className={`app-body ${paddingTop ? 'app-body-top-padding' : ''} ${
            withOutBodyPadding ? 'pb-0' : ''
          }`}
        >
          {children}
        </div>
        {!hideFooter && (
          <div className="app-footer" role="contentinfo">
            <AppFooter />
          </div>
        )}
      </div>
    </Fragment>
  );
};

Layout.propTypes = {
  children: nodePropType.isRequired,
  transparentHeader: PropTypes.bool,
  paddingTop: PropTypes.bool,
};

Layout.defaultProps = {
  transparentHeader: false,
  paddingTop: true,
};

export default Layout;
