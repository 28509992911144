import React from 'react';
import { SOCIAL_MENUS } from './data';

const Menus = () => (
  <div className="social-link">
    {SOCIAL_MENUS.map((menu, index) => (
      <span key={index}>
        <a href={menu.path} target="_blank" rel="noreferrer" title={menu.title}>
          {menu.icon}
        </a>
      </span>
    ))}
  </div>
);

export default Menus;
