import * as React from 'react';

const Icon = ({ color = '#242424' }) => (
  <svg width={28} height={28} viewBox="0 0 28 28">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.526}
      transform="translate(2 2)"
    >
      <circle cx={10.667} cy={10.667} r={10.667} />
      <path d="M24 24l-5.8-5.8" />
    </g>
  </svg>
);

export default Icon;
