import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import SearchIcon from 'svg/SearchIcon';
import './typeahead.scss';
import qs from 'qs';
import { Formik, Form } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { VEHICLES_SEARCH_SUGGESTIONS } from 'services/graphql/queries/query-suggestions';

const VehiclesSearchTypeahead = ({
  placeholder,
  minLength,
  toggle,
  transparent,
  focusOnShow,
}) => {
  const ref = useRef();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //field in the result object which will be shown in the results dropdown
  const filterBy = 'query';

  useEffect(() => {
    if (focusOnShow) {
      ref.current.focus();
    }
  }, [focusOnShow]);
  const [getSearchSuggestions] = useLazyQuery(VEHICLES_SEARCH_SUGGESTIONS, {
    onCompleted: data => {
      setOptions(
        data?.VehiclesSearchSuggestions?.map(i => ({
          [filterBy]: i[filterBy],
        }))
      );
      setIsLoading(false);
    },
    onError: () => {
      setOptions([]);
      setIsLoading(false);
    },
  });

  const navigateToSearchResults = query => {
    navigate(
      `/search-results/?${qs.stringify({
        searchQuery: query,
      })}`
    );
  };
  const searchForSuggestions = async query => {
    if (query.length < 3) return;
    setIsLoading(true);
    await getSearchSuggestions({
      variables: {
        search: query,
      },
    });
  };

  return (
    <Formik
      initialValues={{ search: '' }}
      validate={false}
      onSubmit={values => {
        if (values.search === '') return;
        _.isFunction(toggle) && toggle();
        if (ref.current) {
          ref.current.state.selected = [];
          ref.current.blur();
        }
        navigateToSearchResults(values.search);
      }}
    >
      {({ setFieldValue, handleSubmit }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            className="w-100"
          >
            <div
              className={`type-ahead-search-input ${
                transparent ? 'search-input-transparent' : ''
              }`}
            >
              <AsyncTypeahead
                ref={ref}
                id={'suggestions-global-search'}
                labelKey={filterBy}
                isLoading={isLoading}
                minLength={minLength}
                onSearch={searchForSuggestions}
                options={options}
                placeholder={placeholder}
                onChange={query => {
                  if (ref.current) ref.current.state.selected = [];
                  _.isFunction(toggle) && toggle();
                  navigateToSearchResults(query[0][filterBy]);
                }}
                onInputChange={text => {
                  setFieldValue('search', text);
                }}
                renderMenuItemChildren={option => (
                  <span>{option[filterBy]}</span>
                )}
                delay={500}
              />

              <SearchIcon color={transparent ? '#ffffff' : '#888888'} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

VehiclesSearchTypeahead.propTypes = {
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  asyncSearch: PropTypes.bool,
  searchUrl: PropTypes.string,
  focusOnShow: PropTypes.bool,
};

VehiclesSearchTypeahead.defaultProps = {
  placeholder: '',
  minLength: 1,
  asyncSearch: true,
  transparent: false,
  focusOnShow: true,
};

export default VehiclesSearchTypeahead;
