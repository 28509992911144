import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import VehiclesSearchTypeahead from 'components/VehiclesSearchTypeahead';
import GoodingLogo from 'static/svg/GoodingLogo';
import NavigationItems from './NavItems';
import MyAccount from './MyAccount';
import { BLUE, WHITE } from 'utils/colorsConstants';
import styles from './desktop.module.scss';
import * as amplitude from '@amplitude/analytics-browser';
import { trackEvent, trackLognIn } from 'utils/amplitude';

const DesktopHeader = ({
  transparentHeader,
  hideHeaderItems,
  disableLogoClick,
}) => {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const isBrowser = typeof window !== undefined;
  if (isAuthenticated) {
    amplitude.setGroup('user_email', user?.email);
    amplitude.setUserId(user?.email);
    trackLognIn(user?.email);
  }
  return (
    <header
      className={`${styles.appHeader} ${
        transparentHeader ? styles.transparentHeaderBody : ''
      }`}
    >
      <div className={styles.header}>
        {disableLogoClick ? (
          <GoodingLogo color={transparentHeader ? WHITE : BLUE} />
        ) : (
          <Link to="/" title="link to home page">
            <GoodingLogo color={transparentHeader ? WHITE : BLUE} />
          </Link>
        )}
        <div
          className={`${styles.headerText} ${
            hideHeaderItems ? 'd-none' : 'd-flex'
          }`}
        >
          <NavigationItems />
          <div className={styles.searchVehiclesWrapper}>
            <VehiclesSearchTypeahead
              transparent={transparentHeader}
              placeholder="Search Vehicles"
              focusOnShow={false}
            />
          </div>
          {isBrowser && !isLoading && (
            <div className={styles.myAccountWrapperDesktop}>
              {isAuthenticated ? (
                <MyAccount />
              ) : (
                <Button
                  handleClick={() => {
                    localStorage.setItem(
                      'currentRoute',
                      `${window.location.pathname}${window.location.search}`
                    );
                    amplitude.track('Click on Log In/Sign-up');
                    localStorage.setItem('login', 'isLoggingIn');
                    loginWithRedirect();
                  }}
                >
                  LOGIN / SIGN UP
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

DesktopHeader.propTypes = {
  transparentHeader: PropTypes.bool,
};

export default DesktopHeader;
