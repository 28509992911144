import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PhoneNumber = ({ number }) => {
  const phoneNumber = number.startsWith('+1')
    ? parsePhoneNumberFromString(`${number}`)
    : parsePhoneNumberFromString(`+1${number}`);
  return (
    <>
      {phoneNumber
        ? phoneNumber
            .formatNational()
            ?.replace(/[\(\)']/g, '')
            ?.replace(/\s/g, '-')
        : number}
    </>
  );
};
PhoneNumber.propTypes = {
  number: PropTypes.string,
};

export default PhoneNumber;
