import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { Collapse as RCollapse, Card } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import { MY_ACCOUNT_MENUS } from 'src/data/header';
import styles from '../mobile/Collapse/collapse.module.scss';
import { clearAMSession } from 'utils/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../../../../static/svg/HeartIcon';
import RecentlyViewedIcon from '../../../../static/svg/RecentlyViewedIcon';
import CommunicationPreferencesIcon from '../../../../static/svg/communicationPreferences';
import AccountProfileIcon from '../../../../static/svg/AccountProfileIcon';
import LogOutIcon from '../../../../static/svg/LogOutIcon';
import ProfilePaddleIcon from '../../../../static/svg/ProfilePaddleIcon';

const MyAccount = () => {
  const location = useLocation();
  const { logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const isActive = !!_.find(MY_ACCOUNT_MENUS.SUB_MENUS, {
    link: location.pathname,
  });
  const renderIcons = subMenu => {
    switch (subMenu) {
      case 'Profile':
        return <AccountProfileIcon />;
        break;
      case 'My Favorites':
        return <Icon color={'#1C5BAE'} height={'18px'} width={'20px'} />;
      case 'Registration History':
        return <ProfilePaddleIcon color={'#1C5BAE'} />;
      // case 'recently Viewed':
      //   return <RecentlyViewedIcon />;
      // case 'Communication Preferences':
      //   return <CommunicationPreferencesIcon />;
      default:
        break;
    }
  };
  return (
    <>
      <Button handleClick={toggle} type={isActive ? styles.active : ''}>
        {MY_ACCOUNT_MENUS.MENU}
        <span>{isOpen ? '-' : '+'}</span>
      </Button>
      <RCollapse isOpen={isOpen}>
        <Card className={styles.card}>
          <ul className={`m-0 ${styles.myAccountWrapper}`}>
            {MY_ACCOUNT_MENUS.SUB_MENUS.map((row, index) => (
              <li key={`myAccountSubMenu${index}`}>
                {row.isLogout ? (
                  <Button
                    handleClick={async () => {
                      await clearAMSession();
                      return logout({ returnTo: window.location.origin });
                    }}
                  >
                    <LogOutIcon />
                    Logout
                  </Button>
                ) : (
                  <Link activeClassName={styles.active} to={row.link}>
                    {renderIcons(row.subMenu)}
                    {row.subMenu}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </Card>
      </RCollapse>
    </>
  );
};

export default MyAccount;
