import React from 'react';
import FacebookIcon from 'svg/FacebookIcon';
import InstagramIcon from 'svg/InstagramIcon';
import TwitterIcon from 'svg/TwitterIcon';
import YoutubeIcon from 'svg/YoutubeIcon';
import AppStoreBadge from 'images/app-store-badge.png';
import GoogleStoreBadge from 'images/google-play-badge.png';
import { SOCIAL_MEDIA_LINKS } from 'utils/socialMediaConstants';

const SOCIAL_MENUS = [
  {
    icon: <InstagramIcon />,
    path: SOCIAL_MEDIA_LINKS.instagram,
    title: 'Instagram',
  },
  {
    icon: <FacebookIcon />,
    path: SOCIAL_MEDIA_LINKS.facebook,
    title: 'Facebook',
  },
  {
    icon: <TwitterIcon />,
    path: SOCIAL_MEDIA_LINKS.twitter,
    title: 'Twitter',
  },
  {
    icon: <YoutubeIcon />,
    path: SOCIAL_MEDIA_LINKS.youtube,
    title: 'Youtube',
  },
];

const APP_STORE_LINKS = [
  {
    icon: AppStoreBadge,
    path: 'https://apps.apple.com/us/app/gooding-company/id1349933354',
    title: 'Apple Store',
  },
  {
    icon: GoogleStoreBadge,
    path:
      'https://play.google.com/store/apps/details?id=com.auctionmobility.auctions.goodingco&hl=en_US',
    title: 'Google Store',
  },
];

const CONTACT_INFO = {
  telephone: '310.899.1960',
  telWithCountryCode: '+1-310-899-1960',
  email: 'info@goodingco.com',
};

export { SOCIAL_MENUS, APP_STORE_LINKS, CONTACT_INFO };
