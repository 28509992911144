import * as React from 'react';
const Icon = ({ dark = true }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24">
      <path
        fill={dark ? '#242424' : '#FFF'}
        d="M23.077 19.111c.51 0 .923.423.923.945a.937.937 0 01-.815.938l-.108.006H.923A.934.934 0 010 20.056c0-.485.356-.884.815-.939l.108-.006h22.154zm0-7.555c.51 0 .923.422.923.944a.937.937 0 01-.815.938l-.108.006H.923A.933.933 0 010 12.5c0-.484.356-.884.815-.938l.108-.006h22.154zm0-7.556c.51 0 .923.423.923.944a.938.938 0 01-.815.939l-.108.006H.923A.934.934 0 010 4.944c0-.484.356-.883.815-.938L.923 4h22.154z"
      />
    </svg>
  );
};

export default Icon;
