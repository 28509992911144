import React from 'react';

const AccountProfileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5547 23.5547C23.2578 23.8516 22.9062 24 22.5 24H1.5C1.09375 24 0.742188 23.8516 0.445312 23.5547C0.148438 23.2578 0 22.9062 0 22.5V20.25C0 20 0.140625 19.7422 0.421875 19.4766C0.703125 19.2109 0.921875 19.0312 1.07812 18.9375C1.23438 18.8438 1.375 18.7812 1.5 18.75L8.0625 15.6094C7.125 14.8594 6.42188 13.8281 5.95312 12.5156C5.48438 11.2031 5.25 10.0312 5.25 9V5.25C5.25 4 5.96094 2.8125 7.38281 1.6875C8.80469 0.5625 10.3359 0 11.9766 0C13.6172 0 15.1562 0.5625 16.5938 1.6875C18.0312 2.8125 18.75 4 18.75 5.25V9C18.75 10.0938 18.5234 11.2891 18.0703 12.5859C17.6172 13.8828 16.9219 14.9062 15.9844 15.6562L22.5 18.75L22.875 18.9375C23.125 19.0312 23.375 19.2031 23.625 19.4531C23.875 19.7031 24 19.9688 24 20.25V22.5C24 22.9062 23.8516 23.2578 23.5547 23.5547ZM1.5 20.9531V22.5H22.5V21C22.5 20.5312 22.2969 20.2344 21.8906 20.1094H21.8438L15.3281 17.0156C14.8281 16.7656 14.5469 16.3672 14.4844 15.8203C14.4219 15.2734 14.6094 14.8281 15.0469 14.4844C15.7656 13.8906 16.3125 13.0469 16.6875 11.9531C17.0625 10.8594 17.25 9.875 17.25 9V5.25C17.25 4.4375 16.6875 3.60938 15.5625 2.76562C14.4375 1.92188 13.25 1.5 12 1.5C10.7812 1.5 9.60156 1.92188 8.46094 2.76562C7.32031 3.60938 6.75 4.4375 6.75 5.25V9C6.75 9.84375 6.95312 10.8203 7.35938 11.9297C7.76562 13.0391 8.32812 13.875 9.04688 14.4375C9.45312 14.8125 9.625 15.2734 9.5625 15.8203C9.5 16.3672 9.21875 16.75 8.71875 16.9688L2.10938 20.1094C1.70312 20.2969 1.5 20.5781 1.5 20.9531Z"
        fill="#1C5BAE"
      />
    </svg>
  );
};
export default AccountProfileIcon;
