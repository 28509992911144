import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import HamburgerIcon from 'svg/HamburgerIcon';
import Button from 'components/Button';
import styles from './mobile.module.scss';

const Burger = ({ handleClick, transparentHeader }) => (
  <Button
    type={styles.burger}
    title="open menu"
    handleClick={() => _.isFunction(handleClick) && handleClick()}
  >
    <HamburgerIcon dark={!transparentHeader} />
  </Button>
);

Burger.propTypes = {
  handleClick: PropTypes.func.isRequired,
  transparentHeader: PropTypes.bool,
};

export default Burger;
