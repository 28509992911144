import { gql } from '@apollo/client';

export const GET_VEHICLES_CATALOGUE_DATA = gql`
  query GetVehiclesAndFilters(
    $filtersInput: FiltersInput!
    $pageNumber: Int
    $sortBy: SortBy!
    $updateFilters: Boolean
    $searchQuery: String
  ) {
    getVehicles(
      filtersInput: $filtersInput
      pageNumber: $pageNumber
      sortBy: $sortBy
      updateFilters: $updateFilters
      searchQuery: $searchQuery
    )
      @connection(
        key: "VehiclesCatalogue"
        filter: ["filtersInput", "sortBy"]
      ) {
      filters {
        year {
          start
          end
        }
        make
        estimate {
          value
          count
        }
        auctionType
        itemType
        venue
        auctionYear
        hasReservePrice
        onlineBiddingAvailable
        liveAuctionVenue
        onlineAuctionName
      }
      vehicles {
        objectID
        make
        hasReservePrice
        modelYear
        lowEstimate
        highEstimate
        uniqueEstimate
        salePrice
        auctionType
        auctionName
        lotNumber
        salesForceId
        activeAuction
        cloudinaryImages {
          public_id
          width
          height
          context {
            custom {
              alt
            }
          }
        }

        model
        auctionEndDate
        upcomingAuctionEndDate
        auctionStartDate
        slug
        privateSalesPrice
        itemType
        title
        lotAuctionMobilityId
        auctionAuctionMobilityId
        venueId
        askingPrice
        currency
        askingPriceOverride
        onlineBiddingAvailable
      }
      hitsPerPage
      page
      nbPages
      nbHits
    }
  }
`;

export const GET_NEXT_VEHICLES = gql`
  query GetNextAndPrevVehicles(
    $filtersInput: FiltersInput!
    $pageNumber: Int
    $sortBy: SortBy!
    $vehicleId: String
  ) @connection(key: "nextAndPrevVehicles") {
    NextAndPrevVehicles(
      filtersInput: $filtersInput
      pageNumber: $pageNumber
      sortBy: $sortBy
      vehicleId: $vehicleId
    ) {
      nextVehicleSlug
      nextVehiclePage
      prevVehicleSlug
      prevVehiclePage
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendVehicleInquiryEmail($data: VehicleInquiryData) {
    SendVehicleInquiryEmail(data: $data) {
      code
    }
  }
`;
export const SEND_SUBSCRIPTION_EMAIL = gql`
  mutation subscriptionEmail($fields: SubscriptionEmailFields) {
    subscriptionEmail(fields: $fields) {
      code
      errorMessages
    }
  }
`;
export const SEND_TERMS_AND_CONDITION_EMAIL = gql`
  mutation ContactUsEmail($fields: ContactUsEmailFields) {
    ContactUsEmail(fields: $fields) {
      code
      errorMessages
    }
  }
`;
export const SEARCH_IN_VEHICLES = gql`
  query GetVehiclesAndFilters(
    $pageNumber: Int
    $sortBy: SortBy!
    $searchQuery: String
  ) {
    getVehicles(
      filtersInput: {}
      pageNumber: $pageNumber
      sortBy: $sortBy
      updateFilters: false
      searchQuery: $searchQuery
    ) @connection(key: "VehiclesSearch", filter: ["sortBy", "searchQuery"]) {
      vehicles {
        objectID
        make
        hasReservePrice
        modelYear
        lowEstimate
        highEstimate
        uniqueEstimate
        salePrice
        auctionType
        auctionName
        lotNumber
        salesForceId
        activeAuction
        cloudinaryImages {
          public_id
          width
          height
          context {
            custom {
              alt
            }
          }
        }
        model
        auctionEndDate
        upcomingAuctionEndDate
        auctionStartDate
        slug
        privateSalesPrice
        itemType
        title
        lotAuctionMobilityId
        auctionAuctionMobilityId
        venueId
        askingPrice
        currency
        askingPriceOverride
        onlineBiddingAvailable
      }
      hitsPerPage
      page
      nbPages
      nbHits
    }
  }
`;

// Send follow request for a vehicle
export const FOLLOW_VEHICLE = gql`
  mutation followVehicleRequest($vehicle: FollowVehicleInput!) {
    followVehicle(vehicle: $vehicle) {
      success
      vehicle {
        objectID
        make
        hasReservePrice
        modelYear
        lowEstimate
        highEstimate
        salePrice
        auctionType
        auctionName
        lotNumber
        salesForceId
        activeAuction
        cloudinaryImages {
          public_id
          width
          height
          context {
            custom {
              alt
            }
          }
        }
        model
        auctionEndDate
        upcomingAuctionEndDate
        auctionStartDate
        slug
        privateSalesPrice
        itemType
        title
        lotAuctionMobilityId
        auctionAuctionMobilityId
        venueId
        askingPrice
        currency
        askingPriceOverride
        onlineBiddingAvailable
      }
    }
  }
`;

// Send unfollow request for a vehicle
export const UNFOLLOW_VEHICLE = gql`
  mutation unfollowVehicleRequest($vehicle: FollowVehicleInput!) {
    unfollowVehicle(vehicle: $vehicle) {
      success
    }
  }
`;

// Get followed vehicles
export const GET_FOLLOWED_VEHICLES = gql`
  query getFollowedVehicles {
    followedVehicles {
      vehicles {
        objectID
        make
        hasReservePrice
        modelYear
        lowEstimate
        highEstimate
        uniqueEstimate
        salePrice
        auctionType
        auctionName
        lotNumber
        salesForceId
        activeAuction
        cloudinaryImages {
          public_id
          width
          height
          context {
            custom {
              alt
            }
          }
        }
        model
        auctionEndDate
        upcomingAuctionEndDate
        auctionStartDate
        slug
        privateSalesPrice
        itemType
        title
        lotAuctionMobilityId
        auctionAuctionMobilityId
        venueId
        askingPrice
        currency
        askingPriceOverride
        onlineBiddingAvailable
      }
    }
  }
`;
