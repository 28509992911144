import * as React from 'react';

function CloseIcon(props) {
  const { color = '#FFF' } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fill={color}
        d="M15.74.26a.89.89 0 01.074 1.174l-.074.083L9.257 8l6.483 6.483a.889.889 0 01-1.174 1.331l-.083-.074L8 9.257 1.517 15.74a.889.889 0 01-1.331-1.174l.074-.083L6.743 8 .26 1.517A.889.889 0 011.434.186l.083.074L8 6.743 14.483.26a.889.889 0 011.257 0z"
      />
    </svg>
  );
}

export default CloseIcon;
