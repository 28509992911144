import { gql } from '@apollo/client';

export const VEHICLES_SEARCH_SUGGESTIONS = gql`
  query VehiclesSearchSuggestions($search: String!)
    @connection(key: "searchSuggestions") {
    VehiclesSearchSuggestions(search: $search) {
      query
      objectID
    }
  }
`;
