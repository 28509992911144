import FeatureToggleManager from '../shared/helpers/FeatureToggle/featureToggleManager';
import { AUCTION_MOBILITY_LOGOUT } from '../shared/helpers/FeatureToggle/constants';

export const clearAMSession = () => {
  const featureToggleManager = new FeatureToggleManager();
  if (featureToggleManager.isEnabled(AUCTION_MOBILITY_LOGOUT)) {
    const amURL = process.env.GATSBY_AM_BID_URL;
    return fetch(`${amURL}/logout`, {
      credentials: 'include',
    });
  }
};
