import * as React from 'react';
const Icon = ({ solid = false, color, height, width }) => {
  return (
    <svg
      width={width || '16px'}
      height={height || '16px'}
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5167 2.81665C16.0986 2.39713 15.6018 2.06427 15.0549 1.83714C14.5079 1.61002 13.9214 1.4931 13.3292 1.4931C12.7369 1.4931 12.1505 1.61002 11.6035 1.83714C11.0565 2.06427 10.5597 2.39713 10.1417 2.81665L9.50001 3.46665L8.85834 2.81665C8.44028 2.39713 7.94351 2.06427 7.39653 1.83714C6.84955 1.61002 6.26311 1.4931 5.67084 1.4931C5.07858 1.4931 4.49214 1.61002 3.94516 1.83714C3.39818 2.06427 2.90141 2.39713 2.48334 2.81665C0.716678 4.58332 0.608344 7.56665 2.83334 9.83332L9.50001 16.5L16.1667 9.83332C18.3917 7.56665 18.2833 4.58332 16.5167 2.81665Z"
        stroke={color ? color : solid ? 'none' : '#696969'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={solid ? 'red' : 'none'}
      />
    </svg>
  );
};
export default Icon;
