import { useStaticQuery, graphql } from 'gatsby';
import { SLUGS } from 'utils/webPageTypesConstants';
import {
  REGISTER_TO_BID_PATH,
  CONSIGNMENT_PATH,
  PRIVACY_POLICY_PATH,
  FAQ_PATH,
  VEHICLES_CATALOG_PATH,
  TERMS_PATH,
  CAREERS_PATH,
} from 'utils/pathsConstants';

export const useWebPagesSlugs = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulWebPageDefault(filter: { type: { ne: null } }) {
          nodes {
            slug
            contentful_id
            oldSlugs
            type
          }
        }
      }
    `
  );
  return mapPagesToSlugs(query.allContentfulWebPageDefault?.nodes ?? []);
};

const mapPagesToSlugs = webPages => {
  const slugs = fallBackSlugs();
  webPages.forEach(webPage => {
    const { slug, type } = webPage;
    if (type) slugs[type] = slug;
  });

  return slugs;
};

const fallBackSlugs = () => {
  const slugs = {
    [SLUGS.vehiclesCatalog]: VEHICLES_CATALOG_PATH,
    [SLUGS.careers]: CAREERS_PATH,
    [SLUGS.privacyPolicy]: PRIVACY_POLICY_PATH,
    [SLUGS.registerToBid]: REGISTER_TO_BID_PATH,
    [SLUGS.FAQ]: FAQ_PATH,
    [SLUGS.termsAndConditions]: TERMS_PATH,
    [SLUGS.consignment]: CONSIGNMENT_PATH,
  };
  return slugs;
};
